import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';

import { MD } from '../../config/breakpoints';
import { Markdown } from '../../components/utils';
import Slider from '../../components/slider';

import Icons, { Icon } from './icons';

import * as styles from './blocks.module.scss';

//
// IMAGE
//
export const Image = ({ image, label }) => {console.log(image)
	const images = withArtDirection(image.fluid.gatsbyImageData,  [
		{
			media: `(min-width: ${MD}px)`,
			image: image.fixed.gatsbyImageData,
		},
	]);

	return <GatsbyImage image={images} className={styles.img} alt={label} />
};

Image.propTypes = {
	image: PropTypes.object.isRequired,
	label: PropTypes.string
};

//
// ITEM
//
export const Item = ({ images, header, text, icons }) => (
	<div className={styles.item}>
		<div className={styles.images}>
			{Array.isArray(images) &&
				<Slider>
					{images.map((props, index) => <Image key={index} {...props} />)}
				</Slider>
			}
		</div>
		<div className={styles.content}>
			<div className={styles.top}>
				<h2 className={styles.header}>{header}</h2>
				<Markdown content={text} />
			</div>
			<div>
				<div className={styles.icons}>
					<Icons items={icons} />
				</div>
			</div>
		</div>
	</div>
);


Item.defaultProps = {
	images: null,
	header: null,
	text: null,
	icons: null,
};

Item.propTypes = {
	images: PropTypes.arrayOf(Image.propTypes),
	header: PropTypes.string.isRequired,
	text: PropTypes.string,
	icons: PropTypes.arrayOf(PropTypes.shape(Icon.propTypes)),
};

//
// WRAPPER
//
const Wrapper = ({ items }) =>
	items
		.filter(({ date }) => {
			const timestamp = new Date(date).getTime();
			if (isNaN(timestamp)) {
				return true;
			}

			return timestamp >= new Date().getTime();
		})
		.map(({ images, header, text, icons }, index) => (
			<Item key={index} images={images} header={header} text={text} icons={icons} />
		));

Wrapper.defaultProps = {
	items: [],
};

Wrapper.propTypes = {
	items: PropTypes.arrayOf(PropTypes.shape(Item.propTypes)).isRequired,
};

export default Wrapper;
